.bodyApp {
    min-height: 100vh;
    min-width: 100vw;
    background-color: rgb(206, 195, 195);
}

.divSummer {
    position: absolute;
    height: 50vh;
    width: 60vw;
    top: 2%;
    left: 2%;
    background-image: url(./img/summer.jpg);
    background-size: cover;
    background-position: center center;
    z-index: 2;
}

.divWinter {
    position: absolute;
    height: 55vh;
    width: 55vw;
    top: 43%;
    left: 43%;
    background-image: url(./img/winter.jpg);
    background-size: cover;
    background-position: center center;
}

.divAntiquite {
    position: absolute;
    height: 35vh;
    width: 35vw;
    top: 58%;
    left: 4%;
    background-image: url(./img/antiquity.jpg);
    background-size: cover;
    background-position: center center;
}

.anneaux {
    position: absolute;
    height: 30vh;
    width: 27vw;
    top: 5%;
    left: 68%;
    background-image: url(./img/anneaux.jpg);
    background-size: cover;
    background-position: center center;
    z-index: 3;
}

.linkAccueilSummer {
    position: relative;
    color: white;
    top: 70%;
    left: 85%;
    font-size: 2em;
}

.linkAccueilWinter {
    position: relative;
    color: white;
    top: 10%;
    left: 85%;
    font-size: 2em;
}

.linkAccueilAntiquity {
    position: relative;
    color: white;
    top: 50%;
    left: 60%;
    font-size: 2em;
}

@media screen and (max-width: 800px) {
    .divApp {
        display: flex;
        flex-direction: column;
    }
    .divSummer {
        position: relative;
        height: 40vh;
        width: 100vw;
        top: 0;
        left: 0;
    }
    .divWinter {
        position: relative;
        height: 40vh;
        width: 100vw;
        top: 0;
        left: 0;
    }
    .divAntiquite {
        position: relative;
        height: 20vh;
        width: 100vw;
        top: 0;
        left: 0;
    }
    .anneaux {
        display: none;
    }
    .linkAccueilSummer {
        font-size: 1em;
    }
    .linkAccueilWinter {
        font-size: 1em;
    }
    .linkAccueilAntiquity {
        font-size: 1em;
    }
}