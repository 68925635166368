.bodyXVw {
    min-height: 91vh;
    background-color: rgb(40, 146, 245);
    display: flex;
}

.textXVw {
    color: white;
    margin: auto;
    padding: 5%;
}