.bodyContact {
    min-height: 91vh;
    background-image: url(../img/city.jpg);
    background-size: cover;
    background-attachment: fixed;
    background-position: center center;
}

.contact {
    padding: 5% 0 0 50%;
    color: white;
}

@media screen and (max-width: 800px) {
    .bodyContact {
        background-image: none;
        background-color: rgb(110, 110, 253);
        display: flex;
    }
    .contact {
        margin: auto;
        padding: 0;
    }
    .fontContact {
        font-size: 0.9em;
    }
}