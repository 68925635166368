.bodySummer {
    min-height: 91vh;
}

.piste {
    opacity: 0.35;
    width: 100vw;
    height: 91vh;
}

.Ie {
    position: absolute;
    width: 100px;
    height: 120px;
    top: 12%;
    left: 4%;
}

.IIe {
    position: absolute;
    width: 100px;
    height: 120px;
    top: 12%;
    left: 13%;
}

.IIIe {
    position: absolute;
    width: 100px;
    height: 120px;
    top: 12%;
    left: 22%;
}

.IVe {
    position: absolute;
    width: 100px;
    height: 120px;
    top: 12%;
    left: 31%;
}

.Ve {
    position: absolute;
    width: 100px;
    height: 120px;
    top: 12%;
    left: 40%;
}

.VIe {
    position: absolute;
    width: 100px;
    height: 100px;
    top: 12%;
    left: 49%;
}

.VIIe {
    position: absolute;
    width: 100px;
    height: 120px;
    top: 12%;
    left: 58%;
}

.VIIIe {
    position: absolute;
    width: 150px;
    height: 120px;
    top: 12%;
    left: 67%;
}

.IXe {
    position: absolute;
    width: 100px;
    height: 120px;
    top: 12%;
    left: 80%;
}

.Xe {
    position: absolute;
    width: 80px;
    height: 120px;
    top: 12%;
    left: 89%;
}

.XIe {
    position: absolute;
    width: 100px;
    height: 120px;
    top: 33%;
    left: 4%;
}

.XIIe {
    position: absolute;
    width: 100px;
    height: 120px;
    top: 33%;
    left: 13%;
}

.XIIIe {
    position: absolute;
    width: 100px;
    height: 120px;
    top: 33%;
    left: 22%;
}

.XIVe {
    position: absolute;
    width: 100px;
    height: 120px;
    top: 33%;
    left: 31%;
}

.XVe {
    position: absolute;
    width: 100px;
    height: 120px;
    top: 33%;
    left: 40%;
}

.XVIe {
    position: absolute;
    width: 100px;
    height: 120px;
    top: 33%;
    left: 49%;
}

.XVIIe {
    position: absolute;
    width: 100px;
    height: 120px;
    top: 33%;
    left: 58%;
}

.XVIIIe {
    position: absolute;
    width: 100px;
    height: 120px;
    top: 33%;
    left: 67%;
}

.XIXe {
    position: absolute;
    width: 150px;
    height: 120px;
    top: 33%;
    left: 76%;
}

.XXe {
    position: absolute;
    width: 80px;
    height: 120px;
    top: 33%;
    left: 89%;
}

.XXIe {
    position: absolute;
    width: 100px;
    height: 120px;
    top: 54%;
    left: 4%;
}

.XXIIe {
    position: absolute;
    width: 100px;
    height: 120px;
    top: 54%;
    left: 13%;
}

.XXIIIe {
    position: absolute;
    width: 100px;
    height: 120px;
    top: 54%;
    left: 22%;
}

.XXIVe {
    position: absolute;
    width: 100px;
    height: 120px;
    top: 54%;
    left: 31%;
}

.XXVe {
    position: absolute;
    width: 100px;
    height: 120px;
    top: 54%;
    left: 40%;
}

.XXVIe {
    position: absolute;
    width: 100px;
    height: 120px;
    top: 54%;
    left: 49%;
}

.XXVIIe {
    position: absolute;
    width: 100px;
    height: 120px;
    top: 54%;
    left: 58%;
}

.XXVIIIe {
    position: absolute;
    width: 100px;
    height: 120px;
    top: 54%;
    left: 67%;
}

.XXIXe {
    position: absolute;
    width: 100px;
    height: 120px;
    top: 54%;
    left: 76%;
}

.XXXe {
    position: absolute;
    width: 100px;
    height: 120px;
    top: 54%;
    left: 85%;
}

.XXXIe {
    position: absolute;
    width: 100px;
    height: 100px;
    top: 75%;
    left: 4%;
}

.XXXIIe {
    position: absolute;
    width: 100px;
    height: 100px;
    top: 75%;
    left: 13%;
}

.XXXIIIe {
    position: absolute;
    width: 100px;
    height: 100px;
    top: 75%;
    left: 22%;
}

.XXXIVe {
    position: absolute;
    width: 100px;
    height: 100px;
    top: 75%;
    left: 31%;
}

.affiche:hover {
    transform: scale(2);
    transition-duration: 0.5s;
    z-index: 3;
}

@media screen and (max-width: 800px) {
    .piste {
        display: none;
    }
    .bodySummer {
        background-color: rgba(192, 64, 4, 0.35);
        height: 6850px;
    }
    .Ie {
        width: 100px;
        height: 150px;
        top: 80px;
        left: 20%;
        width: 40%;
    }
    .IIe {
        width: 100px;
        height: 150px;
        top: 280px;
        left: 20%;
        width: 40%;
    }
    .IIIe {
        width: 100px;
        height: 150px;
        top: 480px;
        left: 20%;
        width: 40%;
    }
    .IVe {
        width: 100px;
        height: 150px;
        top: 680px;
        left: 20%;
        width: 40%;
    }
    .Ve {
        width: 100px;
        height: 150px;
        top: 880px;
        left: 20%;
        width: 40%;
    }
    .VIe {
        width: 100px;
        height: 150px;
        top: 1080px;
        left: 20%;
        width: 40%;
    }
    .VIIe {
        width: 100px;
        height: 150px;
        top: 1280px;
        left: 20%;
        width: 40%;
    }
    .VIIIe {
        width: 100px;
        height: 150px;
        top: 1480px;
        left: 20%;
        width: 40%;
    }
    .IXe {
        width: 100px;
        height: 150px;
        top: 1680px;
        left: 20%;
        width: 40%;
    }
    .Xe {
        width: 100px;
        height: 150px;
        top: 1880px;
        left: 20%;
        width: 40%;
    }
    .XIe {
        width: 100px;
        height: 150px;
        top: 2080px;
        left: 20%;
        width: 40%;
    }
    .XIIe {
        width: 100px;
        height: 150px;
        top: 2280px;
        left: 20%;
        width: 40%;
    }
    .XIIIe {
        width: 100px;
        height: 150px;
        top: 2480px;
        left: 20%;
        width: 40%;
    }
    .XIVe {
        top: 2680px;
        left: 20%;
        width: 40%;
    }
    .XVe {
        width: 100px;
        height: 150px;
        top: 2880px;
        left: 20%;
        width: 40%;
    }
    .XVIe {
        width: 100px;
        height: 150px;
        top: 3080px;
        left: 20%;
        width: 40%;
    }
    .XVIIe {
        width: 100px;
        height: 150px;
        top: 3280px;
        left: 20%;
        width: 40%;
    }
    .XVIIIe {
        width: 100px;
        height: 150px;
        top: 3480px;
        left: 20%;
        width: 40%;
    }
    .XIXe {
        width: 100px;
        height: 150px;
        top: 3680px;
        left: 20%;
        width: 40%;
    }
    .XXe {
        width: 100px;
        height: 150px;
        top: 3880px;
        left: 20%;
        width: 40%;
    }
    .XXIe {
        width: 100px;
        height: 150px;
        top: 4080px;
        left: 20%;
        width: 40%;
    }
    .XXIIe {
        width: 100px;
        height: 150px;
        top: 4280px;
        left: 20%;
        width: 40%;
    }
    .XXIIIe {
        width: 100px;
        height: 150px;
        top: 4480px;
        left: 20%;
        width: 40%;
    }
    .XXIVe {
        width: 100px;
        height: 150px;
        top: 4680px;
        left: 20%;
        width: 40%;
    }
    .XXVe {
        width: 100px;
        height: 150px;
        top: 4880px;
        left: 20%;
        width: 40%;
    }
    .XXVIe {
        width: 100px;
        height: 150px;
        top: 5080px;
        left: 20%;
        width: 40%;
    }
    .XXVIIe {
        width: 100px;
        height: 150px;
        top: 5280px;
        left: 20%;
        width: 40%;
    }
    .XXVIIIe {
        width: 100px;
        height: 150px;
        top: 5480px;
        left: 20%;
        width: 40%;
    }
    .XXIXe {
        width: 100px;
        height: 150px;
        top: 5680px;
        left: 20%;
        width: 40%;
    }
    .XXXe {
        width: 100px;
        height: 150px;
        top: 5880px;
        left: 20%;
        width: 40%;
    }
    .XXXIe {
        width: 100px;
        height: 150px;
        top: 6080px;
        left: 20%;
        width: 40%;
    }
    .XXXIIe {
        width: 100px;
        height: 150px;
        top: 6280px;
        left: 20%;
        width: 40%;
    }
    .XXXIIIe {
        width: 100px;
        height: 150px;
        top: 6480px;
        left: 20%;
        width: 40%;
    }
    .XXXIVe {
        width: 100px;
        height: 150px;
        top: 6680px;
        left: 20%;
        width: 40%;
    }
}