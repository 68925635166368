.bodyVIIIs {
    height: 100%;
    margin: 0;
}

.img1VIII {
    opacity: 0.65;
    width: 100vw;
    height: 91vh;
}

.infosVIII {
    position: absolute;
    top: 25%;
    left: 5%;
    display: flex;
    flex-direction: column;
    background-color: rgb(197, 219, 236);
    border-radius: 5px;
    padding: 1%;
}

.title {
    font-weight: bold;
    text-align: center;
}

.details {
    font-size: 0.8em;
}

.info1 {
    color: #ddd;
    background-color: #282E34;
    text-align: center;
    padding: 20px 80px;
    text-align: justify;
}

.img2VIII {
    position: relative;
    opacity: 0.65;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(../../img/summer/VIIIe/porte_drapeaux.jpg);
    min-height: 100vh;
}

.info2 {
    color: #ddd;
    background-color: #282E34;
    text-align: center;
    padding: 20px 80px;
    text-align: justify;
}

.img3VIII {
    position: relative;
    opacity: 0.65;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(../../img/summer/VIIIe/rugbyUSA.jpg);
    min-height: 100vh;
}

@media screen and (max-width: 800px) {
    .img2VIII {
        display: none;
    }
    .img3VIII {
        display: none;
    }
}